<template>
  <div class="offcanvas-body offcanvas-body--view">
    <div
      v-if="item.content.status_table"
      class="mb-1"
    >
      <p>Authoritation<br>
        <span :class="`badge bg-${item.content.status_table ? item.content.status_table.badge_class : 'secondary'}`">{{ item.content.status_table ? item.content.status_table.status : ' -- ' }}</span></p>
      <hr>
    </div>
    <div
      v-if="item.content.title"
      class="mb-1 offcanvas-body--view--title"
    >
      <p>Title<br>
        <strong>{{ item.content ? item.content.title : ' -- ' }}</strong></p>
      <hr>
    </div>
    <div
      v-if="item.content.stay_research_type && item.type == STAYS_RESEARCH"
      class="mb-1"
    >
      <p>Type<br>
        <strong>{{ item.content.stay_research_type ? item.content.stay_research_type.name : ' -- ' }}</strong></p>
      <hr>
    </div>
    <div
      v-if="item.content.phd_role && item.type == PHDS"
      class="mb-1"
    >
      <p>Rol<br>
        <strong>{{ item.content.phd_role ? item.content.phd_role.name : ' -- ' }}</strong></p>
      <hr>
    </div>
    <div
      v-if="item.content.managerial_type && item.type == MANAGERIAL"
      class="mb-1"
    >
      <p>Type of activity<br>
        <strong>{{ item.content.managerial_type ? item.content.managerial_type.name : ' -- ' }}</strong></p>
      <hr>
    </div>
    <div
      v-if="item.content.type && item.type == CONFERENCE"
      class="mb-1"
    >
      <p>Type of activity<br>
        <strong>{{ item.content.type ? item.content.type.name : ' -- ' }}</strong></p>
      <hr>
    </div>
    <div
      v-if="item.content.phd_type && item.type == PHDS"
      class="mb-1"
    >
      <p>Type of activity<br>
        <strong>{{ item.content.phd_type ? item.content.phd_type.name : ' -- ' }}</strong></p>
      <hr>
    </div>
    <div
      v-if="item.content.course_type && item.type == COURSES"
      class="mb-1"
    >
      <p>Type<br>
        <strong>{{ item.content.course_type ? item.content.course_type.name : ' -- ' }}</strong></p>
      <hr>
    </div>
    <div
      v-if="item.content.dissemination_type && item.type == DISSEMINATIONS"
      class="mb-1"
    >
      <p>Type<br>
        <strong>{{ item.content.dissemination_type ? item.content.dissemination_type.name : ' -- ' }}</strong></p>
      <hr>
    </div>
    <div
      v-if="item.content.other_type && item.type == MANAGERIAL || item.type == DISSEMINATIONS"
      class="mb-1"
    >
      <p>Other type<br>
        <strong>{{ item.content.other_type ? item.content.other_type : ' -- ' }}</strong></p>
      <hr>
    </div>
    <div
      v-if="item.content.date"
      class="mb-1"
    >
      <p>{{ item.type == CONFERENCE || item.type == PHDS ? (item.type == CONFERENCE ? 'Date' : 'Presentation date') : 'Begin date' }}<br>
        <strong>{{ item.content ? item.content.date : ' -- ' }}</strong></p>
      <hr>
    </div>
    <div
      v-if="item.content.end_date && item.type != CONFERENCE && item.type != PHDS && item.type != DISSEMINATIONS"
      class="mb-1"
    >
      <p>End date<br>
        <strong>{{ item.content ? item.content.end_date : ' -- ' }}</strong></p>
      <hr>
    </div>
    <div
      v-if="item.type == DISSEMINATIONS && item.content.different_end_date == true && item.content.end_date"
      class="mb-1"
    >
      <p>End date<br>
        <strong>{{ item.content ? item.content.end_date : ' -- ' }}</strong></p>
      <hr>
    </div>
    <div
      v-if="item.content.recipient_name && item.type == PHDS"
      class="mb-1"
    >
      <p>Recipient name<br>
        <strong>{{ item.content ? item.content.recipient_name : ' -- ' }}</strong></p>
      <hr>
    </div>
    <div
      v-if="item.content.host_name && item.type == PHDS || item.type == COURSES || item.type == STAYS_RESEARCH"
      class="mb-1"
    >
      <p>{{ item.type == PHDS ? 'Recipient institution' : 'Institution' }}<br>
        <strong>{{ item.content ? item.content.host_name : ' -- ' }}</strong></p>
      <hr>
    </div>
    <div
      v-if="item.type != PHDS"
      class="mb-1"
    >
      <p>Place<br>
        <strong>{{ item.event_name ? item.event_name : ' -- ' }}, {{ item.country ? item.country : ' -- ' }}, {{ item.postal_code ? item.postal_code : ' -- ' }}</strong></p>
      <hr>
    </div>
    <div
      v-if="item.type == DISSEMINATIONS && item.content.description"
      class="mb-1"
    >
      <p>Description <br>
        <strong
          v-html="item.content.description"
        /></p>
      <hr>
    </div>
    <div class="mb-1">
      <template v-if="item.type == MANAGERIAL">
        <div
          class="form-check"
        >
          <input
            id="tableColumn1"
            type="checkbox"
            class="form-check-input"
            :checked="item.content ? item.content.industrial_property : null"
            disabled
          >
          <label
            class="form-check-label"
            for="tableColumn1"
          >Industrial property</label>
          <div
            v-if="item.content.industrial_property_description && item.industrial_property == true"
            class="mb-1"
          >
            <strong
              v-html="item.content.industrial_property_description"
            />
          </div>
        </div>
        <div
          class="form-check"
        >
          <input
            id="tableColumn1"
            type="checkbox"
            class="form-check-input"
            :checked="item.content ? item.content.exployment_contract : null"
            disabled
          >
          <label
            class="form-check-label"
            for="tableColumn1"
          >Exployment contract</label>
          <div
            v-if="item.content.exployment_contract_description && item.exployment_contract == true"
            class="mb-1"
          >
            <strong
              v-html="item.content.exployment_contract_description"
            />
          </div>
        </div>
      </template>
      <template v-if="item.type == CONFERENCE">
        <div
          class="form-check"
        >
          <input
            id="tableColumn1"
            type="checkbox"
            class="form-check-input"
            :checked="item.content ? item.content['non-presenter-author'] : null"
            disabled
          >
          <label
            class="form-check-label"
            for="tableColumn1"
          >Non presenter author</label>
        </div>
      </template>
      <div
        class="form-check"
      >
        <input
          id="tableColumn1"
          type="checkbox"
          class="form-check-input"
          :checked="item.content ? item.content.published : null"
          disabled
        >
        <label
          class="form-check-label"
          for="tableColumn1"
        >Published</label>
      </div>
      <div class="form-check">
        <input
          id="tableColumn1"
          type="checkbox"
          class="form-check-input"
          :checked="item.content ? item.content.virtual_activity : null"
          disabled
        >
        <label
          class="form-check-label"
          for="tableColumn1"
        >Virtual activity</label>
      </div>
      <hr>
    </div>
    <div
      v-if="item.icrea_remarks"
      class="mb-1"
    >
      <p>Icrea remarks <br>
        <strong
          v-html="item.icrea_remarks"
        /></p>
      <hr>
    </div>
    <div
      v-if="item.trips.length > 0"
      class="mb-1"
    >
      <h4>Trips</h4>
      <table class="table">
        <thead>
          <tr>
            <th>Country</th>
            <th>Begin date</th>
            <th>End date</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="trip in item.trips"
            :key="trip.id"
          >
            <td>{{ trip.country }}</td>
            <td>{{ trip.start_date_formatted }}</td>
            <td>{{ trip.end_date_formatted }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="item.type == DISSEMINATIONS && item.multimedia.length > 0"
      class="mb-1 table-responsive-sm"
    >
      <h4>Multimedia</h4>
      <table class="table">
        <thead>
          <tr>
            <th>Title</th>
            <th>Open</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="multi in item.multimedia"
            :key="multi.id"
          >
            <td>{{ multi.title }}</td>
            <td>
              <a
                :href="`${multi.link}`"
                target="_blank"
                class="btn btn-icon btn-icon rounded-circle btn-flat-dark me-1"
              ><i data-feather="eye" /></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="item.relatedActivities.length > 0"
      class="mb-1"
    >
      <h4>Related activities</h4>
      <table class="table">
        <thead>
          <tr>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="related in item.relatedActivities"
            :key="related.id"
          >
            <td>{{ related.title }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="item.files.length > 0"
      class="mb-1"
    >
      <h4>Documents</h4>
      <table class="table">
        <thead>
          <tr>
            <th>File name</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="file in item.files"
            :key="file.id"
          >
            <td>{{ file.name }}</td>
            <td class="text-end">
              <a
                :href="file.url"
                target="_blank"
              ><i data-feather="download" /></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="item.creator && loggedUser.roles.includes('super-admin')"
      class="mb-1"
    >
      <p>Entry date<br>
        <strong>{{ item.creator.name }} - {{ item.created_at }}</strong></p>
      <hr>
    </div>
    <div
      v-if="item.updater && loggedUser.roles.includes('super-admin')"
      class="mb-1"
    >
      <p>Last update<br>
        <strong>{{ item.updater.name }} - {{ item.updated_at }}</strong>
      </p>
      <hr>
    </div>
    <div
      v-if="hasPermission('update conferences')"
      class="offcanvas-footer mt-auto"
    >
      <a
        title="edit this industrial property"
        class="btn btn-primary mb-1 d-grid w-100"
        @click="$router.push({name: `researcher.${getUrl(item)}.edit`, params: { id: item.id }})"
      >Edit</a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    item: { type: Object, required: true, default: () => {} },
  },
  data() {
    return {
      CONFERENCE: 1,
      PHDS: 2,
      COURSES: 3,
      MANAGERIAL: 4,
      STAYS_RESEARCH: 5,
      DISSEMINATIONS: 6,
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/admin',
    }),
  },
  mounted() {},
  methods: {
    getUrl(activity) {
      switch (activity.type) {
        case 1:
          return 'conferences'
        case 2:
          return 'phds'
        case 3:
          return 'courses'
        case 4:
          return 'managerial'
        case 5:
          return 'stays-research'
        case 6:
          return 'disseminations'
        default:
          return ''
      }
    },
  },
}
</script>

<style>

</style>
